import Link from "next/link";
import classNames from "classnames";

/**
 * @deprecated Use `@/components/base/button` instead.
 */
export default function Button({
  thin = false,
  className = "",
  href = "",
  target = "",
  variant = "primary",
  shadow = "",
  ...props
}) {
  const paddingClasses = thin ? "px-8 py-2" : "px-12 py-3.5";
  const variantClasses = {
    primary:
      "bg-light-purple hover:bg-dark-purple disabled:bg-disabled-purple text-white",
    alternate:
      "border border-light-purple shadow-border-focus hover:border-dark-purple bg-transparent hover:bg-dark-purple text-dark-purple hover:text-white",
    "primary-light":
      "bg-white hover:bg-dark-purple disabled:bg-background-dark-gray text-dark-purple hover:text-white",
    "alternate-light":
      "border border-white hover:border-dark-purple bg-transparent hover:bg-dark-purple text-white hover:text-white",
    pink: "bg-[#FF5B5D] hover:bg-pink text-white",
  }[variant];
  const shadowClasses = {
    small: "shadow-sm",
    medium: "shadow-md",
    large: "shadow-lg",
    "x-large": "shadow-xl",
  }[shadow];

  const button = (
    <button
      className={classNames(
        "rounded-full font-bold whitespace-nowrap",
        paddingClasses,
        variantClasses,
        shadowClasses,
        className
      )}
      {...props}
    >
      {props.children}
    </button>
  );

  return href ? (
    <Link href={href}>
      <a target={target}>{button}</a>
    </Link>
  ) : (
    button
  );
}
